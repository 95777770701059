<template>
  <div class="fullscreen-container" @keydown="handleKeyDown" >
    <button class="close-button" @click="closeFullscreen" ref="fullscreenContainer">Fechar</button>
    <div class="card-container">
      <div v-for="(person, index) in readPeople" :key="index" class="card">
        <div>
          <h2 class="card-title">{{ person.name }}</h2>
          <p class="card-details">{{ person.room }} - {{ person.location }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['readPeople', 'showFullScreenCard'],
  mounted() {
    if (this.showFullScreenCard) {
      this.$refs.fullscreenContainer.click()
    }
  },
  methods: {
    closeFullscreen() {
      this.$emit('closeFullscreen');
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.closeFullscreen();
      }
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #204da5; /* Cor do fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 300px;
  height: 200px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-size: 32px; /* Aumenta o tamanho do texto */
  text-align: center; /* Centraliza o texto */
  margin-bottom: 10px; /* Espaçamento inferior */
}

.card-details {
  font-size: 22px; /* Aumenta o tamanho do texto */
  text-align: center; /* Centraliza o texto */
}
</style>
