<template>
  <div id="app">
    <h1>Pacientes</h1>
    <input type="text" v-model="newName" placeholder="Digite um nome">
    <select v-model="newRoomType" class="select-room">
      <option value="Sala">Sala</option>
      <option value="Consultório">Consultório</option>
    </select>
    <input type="text" v-model="newLocation" placeholder="Digite o local (sala, consultório, etc)">
    <button @click="addName" class="btn">Adicionar</button>
    <button @click="speakNames" class="btn">Ler Nomes</button>
    <button @click="emptyNames" class="btn">Limpar</button>
    <button @click="showFullScreenCard = true" class="btn" :disabled="!listReadPeople.length">Mostrar Chamados</button>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Sala/Consultório</th>
            <th>Local</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(person, index) in people" :key="index" :class="{ 'read': person.read }">
            <td><input type="checkbox" v-model="person.read" /></td>
            <td>{{ person.name }}</td>
            <td>{{ person.room }}</td>
            <td>{{ person.location }}</td>
            <td>
              <a class="btn" @click="removePerson(index)">Remover</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showFullScreen" class="fullscreen-message">{{ currentName }} - {{ currentRoom }} - {{ currentLocation }}
    </div>
    <div v-if="showFullScreen" class="timer">{{ timer }}</div>
    <CardView :readPeople="listReadPeople" v-if="showFullScreenCard" @closeFullscreen="closeFullscreen" />
  </div>
</template>

<script>
import CardView from './components/CardView.vue'

export default {
  name: 'App',
  components: {
    CardView
  },
  data() {
    return {
      newName: '',
      newRoomType: 'Sala',
      newLocation: '',
      people: [
        { name: 'Heber', room: 'Sala', location: '1', read: false },
        { name: 'Cristina', room: 'Consultório', location: '2', read: false }
      ],
      showFullScreen: false,
      showFullScreenCard: false,
      currentName: '',
      currentRoom: '',
      currentLocation: '',
      currentIndex: 0,
      timer: 10, // Alteração para 10 segundos
      speechSynthesisSupported: 'speechSynthesis' in window,
      interval: null,
      timeoutId: null,
      readingNames: false
    };
  },
  computed: {
    listReadPeople() {
    return this.people.filter(person => person.read);
  }
  },
  methods: {
    closeFullscreen() {
      this.showFullScreenCard = false
    },
    addName() {
      if (this.newName.trim() !== '' && this.newLocation.trim() !== '') {
        this.people.push({
          name: this.newName,
          room: this.newRoomType,
          location: this.newLocation,
          read: false // Adiciona a propriedade "read" ao adicionar um novo nome
        });
        this.newName = '';
        this.newLocation = '';
      }
    },
    speakNames() {
      if (this.speechSynthesisSupported) {
        this.currentIndex = 0;
        this.readingNames = true; 
        this.showFullScreen = true;
        this.speakNextName();
      } else {
        alert('Seu navegador não suporta síntese de fala.');
      }
    },
    speakNextName() {
      if (this.currentIndex < this.people.length) {
        const person = this.people[this.currentIndex];
        if(!person.read) {
          this.currentName = person.name;
          this.currentRoom = person.room;
          this.currentLocation = person.location;

          // Marcar a pessoa como lida
          person.read = true;

          const speech = new SpeechSynthesisUtterance();
          speech.lang = 'pt-BR';
          speech.text = `Chamando paciente ${this.currentName}. ${this.currentRoom === 'Sala' ? 'Dirija-se a' : 'Dirija-se ao'} ${this.currentRoom}, ${this.currentLocation}.`;
          speechSynthesis.speak(speech);

          this.timer = 10; 
          if (this.interval) {
            clearInterval(this.interval)
          }
          this.startTimer()

          this.timeoutId = setTimeout(this.speakNextName, this.timer * 1000)
          this.currentIndex++;
        } else {
          this.currentIndex++;
          this.speakNextName()
        }
       
      } else {
        this.showFullScreen = false;
      }
    },
    startTimer() {
      this.interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.interval);
          this.interval = null
        }
      }, 1000)
    },
    emptyNames() {
      this.people = []
    },
    cancelSpeakNames() {
      this.readingNames = false
      this.showFullScreen = false;
      this.timer = 0
      clearInterval(this.interval)
      speechSynthesis.cancel()
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    handleKeyDown(event) {
      if (event.key === 'Escape' && this.readingNames) { 
        this.cancelSpeakNames(); 
      }
    },
    removePerson(index) {
      this.people.splice(index, 1)
    }
  },
  mounted() {
    this.$el.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    this.$el.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: #333;

  input[type="text"],
  select {
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    font-size: 1rem;
    background-color: #fff;
    width: 200px; // largura ajustável conforme necessário
  }

  .btn {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    border: none;
    cursor: pointer;
    background-color: #204da5;
    color: #fff;
    font-size: 1rem;
    margin: 0.5rem;

    &:hover {
      background-color: darken(#204da5, 10%);
    }
  }

  .table {
    display: flex;
    justify-content: center;
  }

  table {
    width: 600px;
    border-collapse: collapse;
    margin-bottom: 1rem;

  }

  th,
  td {
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  th {
    background-color: #204da5;
    color: white;
    text-align: center;
  }

  .person {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #555;
  }

  .fullscreen-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #204da5;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .timer {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: white;
    z-index: 9999;
  }

  tr.read td {
    text-decoration: line-through;
  }
}
</style>
